<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col
                cols="12"
                md="7"
            >
                <div class="text-center text-md-start mb-4">
                    <span class="display-2">Disclaimer</span>
                </div>
                <div v-if="userDomain === 'activest'">
                    <p>
                        Activest Wealth Management, LLC is an investment adviser registered with the United States Securities and Exchange Commission. Such registration does not imply that the Firm is recommended or approved by the United States government or any regulatory agency. Activest Wealth Management, LLC  may only transact business or render personalized investment advice in those states and international jurisdictions where it is registered, has notice filed, or is otherwise excluded or exempted from registration requirements. Activest Wealth Management, LLC may not provide investment advisory services to any resident of states in which the Adviser is not registered. The purpose of this material is for information distribution only and should not be construed as an offer to buy or sell securities or to offer investment advice. Past results are no guarantee of future results and no representation is made that a client will or is likely to achieve results that are similar to those described. An investor should consider his or her investment objectives, risks, charges and expenses carefully before investing. Please refer to Activest Wealth Management, LLC‘s ADV Part 2 (firm brochure) for additional information and risks.
                    </p>

                    <p>
                        The information contained in this website is provided for informational purposes only and solely for your use. Such information may be obtained, derived, or compiled by Activest Wealth Management, LLC ("Actlvest") from a variety or sources in a manner considered by Activest to be reasonable, but it is not warranted to be complete or accurate. Activest expressly disclaims any responsibility for: (i) the accuracy of any proprietary or external models, pricing services, assumptions, and/or estimates used in deriving the valuations set forth in this website; (ii) any errors or omissions in market data or reference sources on which such valuations are based or in computing or disseminating the valuations; and (iii) any uses to which valuations are put.
                    </p>

                    <p>
                        This website does not reflect prices at which Activest or any of its affiliates would be willing to sell, buy, or assign any product listed herein, nor is it intended to suggest that a secondary market is guaranteed for such products. Valuations of particular investments contained in this website may not represent the actual or indicative prices at which such investments could be liquidated in the market, or at which similar investments could be purchased, nor do they reflect fees, spreads, or other charges that may apply in connection with transactions.
                    </p>


                    <p>
                        Valuations for certain types of investments may reflect estimates and assumptions about relevant future market conditions and other matters, all of which are subject to change without notice. The liquidation value of investments in real estate assets may vary significantly from any valuation provided by Activest due to factors such as rental income fluctuation, depreciation, property tax value changes, and changes in real estate market values. Other non-liquid or special assets may be subject to other unique considerations affecting the accuracy or utility of any valuation provided herein.
                    </p>

                    <p>
                        (Certain hedge funds marked with (**) are subject to restrictions on redemptions or are undergoing restructuring, liquidation, or other special circumstances, any of which may cause stated values to be speculative or unreliable. The exchange rates used in currency conversions are spot rates quoted by independent pricing sources selected by Activest and are not warranted to represent the most favorable rates available in the market.)
                    </p>

                    <p>
                        This website does not constitute investment advice. Investors should obtain individual advice based on their own particular circumstances before making investment decisions based on information contained in this website. Information contained herein has been derived from sources believed to be reliable but is not guaranteed as to accuracy and does not purport to be a complete analysis of the security, company or industry involved. The attached website should not be construed as an offer to buy or sell or a solicitation of an offer to buy or sell any securities. It does not involve the rendering of personalized investment advice. Different types of investments involve varying degrees of risk, and there can be no assurance that any specific investment or strategy will be suitable or profitable for a client's portfolio. You may experience a loss.
                    </p>

                    <p>
                        To the extent Activest relies on information or statements received from other sources, such as your qualified custodian, Activest does not independently verify such information. For further information regarding valuations, please refer to the account agreement governing your relationship with Activest.
                    </p>

                    <p>
                        Please review this website carefully and notify Activest of any errors or discrepancies.
                    </p>
                </div>
                <div v-if="userDomain === 'axxets'">
                    <p>
                        The information contained in this website is provided for informational purposes only and solely for your use.  Such information may be obtained, derived, or compiled by Axxets Management INC (“Axxets”) from a variety of sources in a manner considered by Axxets to be reasonable, but it is not warranted to be complete or accurate.  Axxets expressly disclaims any responsibility for: (i) the accuracy of any proprietary or external models, pricing services, assumptions, and/or estimates used in deriving the valuations set forth in this website; (ii) any errors or omissions in market data or reference sources on which such valuations are based or in computing or disseminating the valuations; and (iii) any uses to which valuations are put.
                    </p>

                    <p>
                        This website does not reflect prices at which Axxets or any of its affiliates would be willing to sell, buy, or assign any product listed herein, nor is it intended to suggest that a secondary market is guaranteed for such products.  Valuations of particular investments contained in this website may not represent the actual or indicative prices at which such investments could be liquidated in the market, or at which similar investments could be purchased, nor do they reflect fees, spreads, or other charges that may apply in connection with transactions.
                    </p>

                    <p>
                        Valuations for certain types of investments may reflect estimates and assumptions about relevant future market conditions and other matters, all of which are subject to change without notice.  The liquidation value of investments in real estate assets may vary significantly from any valuation provided by Axxets due to factors such as rental income fluctuation, depreciation, property tax value changes, and changes in real estate market values.  Other non-liquid or special assets may be subject to other unique considerations affecting the accuracy or utility of any valuation provided herein.
                    </p>

                    <p>
                        [Certain hedge funds marked with (**) are subject to restrictions on redemptions or are undergoing restructuring, liquidation, or other special circumstances, any of which may cause stated values to be speculative or unreliable.  The exchange rates used in currency conversions are spot rates quoted by independent pricing sources selected by Axxets and are not warranted to represent the most favorable rates available in the market.]
                    </p>

                    <p>
                        Nothing in this website constitutes investment advice.  Investors should obtain individual advice based on their own particular circumstances before making investment decisions based on information contained in this website.
                    </p>

                    <p>
                        To the extent Axxets relies on information or statements received from other sources, such as your qualified custodian, Axxets does not independently verify such information.  For further information regarding valuations, please refer to the account agreement governing your relationship with Axxets.
                    </p>

                    <p>
                        Please review this website carefully and notify Axxets of any errors or discrepancies.
                    </p>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {getUserDomain} from "../utils/domains.utils";

    export default {
        name: "Disclaimer",
        computed: {
            userDomain(){
                return getUserDomain()
            }
        }
    }
</script>

<style scoped>

</style>